@import '~bootstrap/scss/_functions';
@import 'theme/functions';

@import '~bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'user-variables';
@import 'theme/variables';
@import '~bootstrap/scss/variables';

h3.admin-title, h5.admin-title, h6.admin-title {
  font-family: $font-family-base;
  color: $main-grey !important;
}

.btn-primary.admin-primary {
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
  font-weight: 600;
  &.big {
    font-size: 16px;
    //padding: 15px 22px;
  }
}

.crop-modal {
  .modal-content {
    border-radius: 2px;
    border: 4px solid #fff;
  }
}

.deleteFile {
  font-size: 10px;
}

.sticky-sidebar {
  top: 17px;
  z-index: 0 !important;
}

.container.admin {
  @media (min-width: 1540px) {
    max-width: 1480px;
  }
  @media (min-width: 1200px) and (max-width: 1539px) {
    max-width: 1140px;
  }
  p {
    font-size: 14px;
  }
  .company-list {
    border: 1px solid #ccc9d1;
    img {
      width: 50px;
      max-width: 100%;
    }
  }
  .admin-title.companyRightManagePeople {
    font-size: 17px;
  }
  .alert {
    font-size: 14px;
  }
  .navbar-collapse {
    background-color: #FFFFFF !important;
  }
  .navbar-vertical-content {
    .nav-link-text, .navbar-vertical-label {
      font-family: $font-family-base;
    }
  }
}

.table.admin-table, .admin-table {
  th, td {
    font-size: 14px;
  }
  tr td:first-child {
    padding-left: 1.25rem !important;
  }
}

.table tr.admin-table td:first-child {
  padding-left: 12px !important;
}

.table-responsive.scrollbar-visible {
  overflow-x: auto !important;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height: 8px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.bottom-table {
  font-size: 13px;
  line-height: 22px;
}

.table-select {
  font-size: 13px;
}

.table-membres.table.admin-table {
  tr td:first-child {
    padding-left: 0.75rem !important;
  }
}

.companyPrintPDF {
  display: block;
  margin: 5mm 10mm !important;
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
  .title-after-logo {
    font-size: 0.8rem !important;
  }
  .smaller-p {
    font-size: 0.7rem !important;
    line-height: normal !important;
  }
}

@media print {
  @page {
    size: a4 portrait;
    margin: 0mm !important;
  }
}

.calendar-tred h4 {
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
}

.fc-event {
  &.holidays {
    height: 1px;
    padding: 0 !important;
  }
}

.badge-points {
  font-size: 12px;
}

// permet de fixer le header des tableau

.table-container {
  max-height: 70vh;
  overflow-y: scroll;
  position: relative;
}

.table-fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff ;
  color: #000000;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-select__menu {
  position: absolute !important;
  z-index: 20 !important;
}

.contracts {
  .table-responsive {
    height: 800px;
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
  .table thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    min-width: 175px;
  }
  .fixed-column {
    position: sticky;
    left: 0;
    background-color: white !important;
  }
}







